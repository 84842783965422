<template>
  <div class="dbkfooter">
      <div class="footer">
        <img src="../assets/images/dbk/bottomlogo.png">
        <p>
          版权所有 © 2009 中国大百科全书出版社　技术支持：北京稻壳科技<br>
          联系方式：电话 13901395087 e-mail:ltr@ecph.com.cn<br>
          Copyright © 2009 by Encyclopedia of China Publishing House.All Rights Reserved<br>
          京ICP备 12000488 号-2 （总）网出证（京）字第205号<br>
          京公网安备 11010202008121 号
        </p>
      </div>
    </div>
</template>

<script>
export default {
  name: "dbkfooter",
  data () {
    return {
    }
  },
  components:{
  },
  created () {
  },
  mounted() {
    
  },
  methods:{
    
  }
}
</script>

<style scoped>
/*footer*/
.dbkfooter{
  background:#ebebeb;
  border:1px solid #d9d9d9;
  height: 114px;
}
.footer{
  width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.footer p{
  line-height:18px;
  color:#999999;
  font-size:12px;
  text-align: right;}
</style>
<style>
html, body, #app{
  height: 100%;
  background: #f5f6f5 !important;
}
</style>
