<template>
  <div class="detail">
    <div class="detailcont">
      <div class="detailpadding">
        <dbkheader v-model="query" @search="dbkSearch"></dbkheader>
        <div class="detailLeft width1200">
          <h1>{{item.cnName}}</h1>
          <div class="info">
            <p v-if="item.pyName">
              <span>拼音</span>{{item.pyName}}
            </p>
            <p v-if="item.enName">
              <span>外文</span>{{item.enName}}
            </p>
            <p v-if="item.author">
              <span>作者</span>{{item.author}}
            </p>
            <p v-if="item.sourceFrom">
              <span>出处</span>{{item.sourceFrom}}
            </p>
          </div>
          <div class="main">正文</div>
          <div ref="info" class="maindetal" v-html="item.content">

          </div>
          <!-- <div class="geography"><span></span>地理位置<p></p></div>
          <div class="geogrcont">
            <div class="gergrtext wow fadeInLeft" data-wow-duration=".3s" data-wow-delay=".3s">
              <p>地球上现存的生物估计有200万～450万种；已经灭绝的种类更多，估计至少也有1500万种。从北极到南极，从高山到深海，从冰雪覆盖的冻原到高温的矿泉，都有生物存在。</p>
              <p>它们具有多种多样的形态结构，它们的<a class="signblue">生活方式</a>也变化多端。从生物的基本结构单位──细胞的水平来考察，有的生物尚不具备细胞形态，在已具有细胞形态的生物中，有的由原核细胞构成，有的由真核细胞构成。</p>
              <p>从组织结构水平来看，有的是单生的或群体的<a class="signblue">单细胞生物</a>,有的是<a class="signblue">多细胞生物</a>，而多细胞生物又可根据组织器官的分化和发展而分为多种类型。从营养方式来看，有的是光合自养，有的是吸收异养或腐食性异养，有的是吞食异养。</p>
            </div>
            <div class="gergrimg wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".5s">
              <img src="../assets/images/search1.png">
            </div>
            <div class="gergrtext  wow fadeInLeft" data-wow-duration=".7s" data-wow-delay=".7s">
              <p><a class="signblue">陈世骧</a>于1979年提出 6界系统。这个系统由<a class="signblue">非细胞总界</a>、原核总界和真核总界3个总界组成，代表生物进化的3个阶段。非细、原核总界和真核总界3个总界组成，代表生物进化的3个阶段。非细胞总界中只有1界，即病毒界。原核总界分为细菌界和蓝菌界。真核总界包括植物界、真菌界和动物界，它们代表真核生物进化的3条主要路线。全世界的化工能源（石油、煤等）贮备总是有限的，总有一天会枯竭。因此，自然界中可再生的生物资源(生物量) 又重新被人所重视。自然界中的生物量大多是纤维素、半纤维素、木质素。将化学的、物理的和生物学的方法结合起来加工，就可以把纤维素转化为酒精，用作能源。有人估计，到20世纪末全世界的汽车约有35%将使用生物量（酒精）沼气是利用生物量开发能源的另一产品。</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <dbkfooter></dbkfooter>
  </div>
</template>

<script>
import dbkheader from '@/components/dbkheader.vue';
import dbkfooter from '@/components/dbkfooter.vue';
import { WOW } from "wowjs";
import {dbkInfo} from '@/api/home'
export default {
  name: "dbkdetail",
  data () {
    return {
      query:'',
      name:'',
      item:{}
    }
  },
  components:{
    dbkheader,
    dbkfooter
  },
  watch: {
    "$route.query": {
      handler: function (val) {
        this.getDbkInfo();
      },
      immediate: true,
      deep: true,
    },
  },
  created () {
  },
  mounted() {
    new WOW().init();
    let info_vm=this.$refs.info;
    info_vm.addEventListener("click",(event)=>{
      const ev = window.event || event;
      const path = event.path || (event.composedPath && event.composedPath());
      let arr=path.map((item)=>{return {class:item.className}});
      let flag=false;
      arr.forEach(item=> {
        if(item.class=="hot_link"){
            flag=true;
        }
      })

      if(flag){
        this.$router.push({query:{name:event.target.innerText,query:this.query}})
      }


    })
  },
  methods:{
    getDbkInfo(){
      this.query=this.$route.query.query;
      this.name=this.$route.query.name;
      dbkInfo(this.query,this.name).then((res)=>{
        this.item=res.data.item;
      })
    },
    dbkSearch(){
      this.$router.push({path:'dbkView',query:{inputVal:this.query}})
    }
  }
}
</script>

<style scoped>
.maindetal >>> .story{
  text-align: center;
}
.maindetal >>> .pic_title{
  display: block;
}
.maindetal >>> p{
  text-align: justify;
  padding: 0 0 20px;
  line-height: 30px;
}
.maindetal >>> img{
  max-width: 100%;
}
.maindetal >>> .hot_link{
  color: #0f88eb;
  cursor: pointer;
}
.detail{
  background: #f5f6f5 !important;
  min-height: 100%;
}
.detailcont{
  min-height: 100vh;
  height: auto;
  margin: 0 auto -116px;
}
.detailpadding{
  padding-bottom: 116px;
}
.width1200{
  width: 1200px;
  margin: 20px auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(58, 58, 58, 0.06);
}
.detailLeft{
  padding:45px 62px 46px 62px;
  background:white;
  box-shadow:0 1px 3px 0 rgba(58, 58, 58, .22);
  -webkit-box-shadow:0 1px 3px 0 rgba(58, 58, 58, .22);
  -moz-box-shadow:0 1px 3px 0 rgba(58, 58, 58, .22);
  -ms-box-shadow:0 1px 3px 0 rgba(58, 58, 58, .22);
  position: relative;
  text-align: left;
}
.detailLeft h1{
  line-height:48px;
  font-size:30px;
  color:#1a1a1a;
  font-weight: normal;
  margin-bottom:20px;}
.detailLeft h1 span{
  font-size:16px;
  color: #4D4D4D;
}
.detailLeft .info{
  border-bottom:1px dashed #e5e5e5;
  margin-bottom:26px;}
.detailLeft .info p{
  line-height:24px;
  padding:8px 0;
  color:#999999;
  padding-left:68px;
  position: relative;
  border-top:1px dashed #e5e5e5;
  font-size: 14px;
}
.detailLeft .info p span{
  position: absolute;
  left:0;
  color:#333333;}
.main{
  width: 70px;
  height: 24px;
  background: #2464A9;
  border-radius: 2px;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  margin-top: 51px;
  margin-bottom: 20px;
}
.maindetal{
   padding: 20px 0px 40px 0px;
   border-top: 1px solid #E6E6E6;
   color: #333333;
   font-size: 16px;
   line-height: 30px;
}
.maindetal p{
  text-indent: 32px;
  text-align: justify;
  padding: 0 0 20px;
  line-height: 30px;
}
.signblue{
  color: #2464A9;
}
.geography{
  font-size: 26px;
  color: #1A1A1A;
  display: flex;
  align-items: center;
}
.geography span{
  display: inline-block;
  width: 10px;
  height: 28px;
  background: #2464A9;
  margin-right: 9px;
}
.geography p{
  flex: 1;
  height: 1px;
  background:#E6E6E6;
  margin-left: 27px;
}
.gergrtext{
  padding-top: 30px;
}
.gergrtext p{
  color: #333333;
  font-size: 16px;
  line-height: 30px;
  text-indent: 32px;
}
.gergrimg{
  width: 667px;
  height: 278px;
  margin: 30px auto;
}
.gergrimg img{
  width: 100%;
  height: 100%;
}
</style>
<style>
html, body, #app{
  height: 100%;
  background: #f5f6f5 !important;
}
</style>
