<template>
   <div class="dbkHeader">
      <div class="dbkLogo">
        <img src="../assets/images/dbk/logo.png" class="logo">
        <div class="ency">
            <div class="dbksearch">
                <input type="text" ref="input"  placeholder="请输入检索内容" @input="handleInput" @keyup.enter="dbkSearch">
                <div class="searchBtn" @click="dbkSearch">搜索</div>
            </div>
            <el-tooltip effect="light" content="美育检索" placement="top">
              <div class="reverse" @click="meiyuSearch">
                <img src="../assets/images/dbk/mylogo.png">
              </div>
            </el-tooltip>
        </div>
      </div>
   </div>
</template>

<script>
export default {
  name: "dbkheader",
  data () {
    return {

    }
  },
  model:{
    prop: 'value',
    event: 'input'
  },
  props: {
    value:[String, Number],
    default:''
  },
  computed:{
  },
  created () {
  },
  mounted () {
    this.getInput().value = this.value;
  },
  methods:{
    dbkSearch(){
      if(!this.value || !this.value.trim()){
        this.$message.error("请输入检索词！");
      }else{
        this.$emit('search');
      }
    },
    meiyuSearch(){
      this.$router.push({path:'/search',query:{inputVal:this.value}});
    },
    handleInput(event){
      this.$emit('input',event.target.value);
    },
    getInput() {
        return this.$refs.input;
    },
  }

}
</script>

<style scoped>
.dbkHeader{
  width: 100%;
  height: 288px;
  background-image: url("../assets/images/dbk/bg1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.dbkLogo{
  width: 870px;
  margin: auto;
}
.logo{
   padding: 62px 0px 29px 0px;
}
.dbksearch{
  width: 734px;
  height: 52px;
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.dbksearch input{
  flex: 1;
  border: none;
  outline: none;
  padding: 3px 17px 0px 17px;
  height: 100%;
  box-sizing: border-box;
  font-size: 14px;
}
.dbksearch input::placeholder{
  font-size: 14px;
  color: #999999;
}
.searchBtn{
  width: 82px;
  height: 100%;
  background: #C01937;
  border-radius: 0px 4px 4px 0px;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 52px;
  cursor: pointer;
}
.ency{
    display: flex;
}
.reverse{
    width: 114px;
    height: 52px;
    background: rgba(255,255,255,.28);
    border-radius: 26px;
    margin-left: 17px;
    line-height: 52px;
    cursor: pointer;
}
.reverse img{
    width: 57px;
    height: 26px;
    vertical-align: middle;
}
</style>
